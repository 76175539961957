import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../assets/img/logo.svg";
import Download from "../assets/img/download_button.svg";
import MenuIcon from "../assets/img/menu_icon.svg";
import MenuClose from "../assets/img/menu_close.svg";
import { markDownloadClicked } from "../functions/analytics";
// import "./MenuButton.css"; // Import CSS for MenuButton styles

const MenuButton = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDownloadClick = () => {
    setIsPopupOpen(true);
    setIsModalOpen(false); // Close the modal when opening popup
  };

  const handleClosePopup = (e) => {
    if (e.target.id === "popupBackground") {
      setIsPopupOpen(false);
    }
  };

  const handleMenuIconClick = () => {
    setIsModalOpen(!isModalOpen);
    if (isPopupOpen) setIsPopupOpen(false); // Close popup if opening modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const textStyle = {
    color: "var(--white, #FFF)",
    fontFamily: "KHTeka",
    fontSize: isMobile ? "18px" : "32px",
    // fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "100%",
    letterSpacing: "-0.32px",
    textTransform: "uppercase",
    cursor: "pointer", // Make it obvious it's clickable
    marginBottom: "1rem",
  };

  if (isMobile) {
    return (
      <>
        <ul
          id="menuButton"
          className="md:flex text-[15px] fixed top-0 left-0 w-full p-3 py-1 flex justify-between items-center z-50 text-white "
        >
          <li className="self-center pt-2 items-center justify-center mix-blend-normal">
            <button
              className="text-lg self-center"
              onClick={() => {
                navigate("/");
                scrollToTop();
              }}
            >
              <img src={Logo} alt="GHS" className="h-[32px] self-center" />
            </button>
          </li>
          <li className="self-center pt-2 mix-blend-normal">
            <button
              className="self-center mix-blend-normal"
              onClick={handleMenuIconClick}
            >
              <img
                src={!isModalOpen ? MenuIcon : MenuClose}
                alt="Download"
                className="h-[32px]"
              />
            </button>
          </li>
        </ul>

        {isModalOpen && (
          <div className="fixed inset-0 flex justify-center items-center bg-black z-40">
            <div className="bg-black p-8 rounded-lg">
              <ul className=" items-center p-0" style={textStyle}>
                <li
                  className={`cursor-pointer hover:underline py-4 mx-auto text-center ${
                    location.pathname === "/" ? "underline" : ""
                  }`}
                  onClick={() => {
                    navigate("/");
                    scrollToTop();
                    handleCloseModal();
                  }}
                >
                  Home
                </li>
                <li
                  className={`cursor-pointer hover:underline py-4 mx-auto text-center ${
                    location.pathname === "/Mission" ? "underline" : ""
                  }`}
                  onClick={() => {
                    navigate("/Mission");
                    handleCloseModal();
                  }}
                >
                  Our Mission
                </li>
                <li
                  className={`cursor-pointer hover:underline py-4 mx-auto text-center ${
                    location.pathname === "/FAQs" ? "underline" : ""
                  }`}
                  onClick={() => {
                    navigate("/FAQs");
                    handleCloseModal();
                  }}
                >
                  FAQs
                </li>
                <li
                  className={`cursor-pointer hover:underline py-4 mx-auto text-center ${
                    location.pathname === "/Partners" ? "underline" : ""
                  }`}
                  onClick={() => {
                    navigate("/Partners");
                    handleCloseModal();
                  }}
                >
                  Partners
                </li>

                <li className="self-center py-4 mix-blend-normal">
                  <button
                    className="self-center mix-blend-normal"
                    onClick={handleDownloadClick}
                  >
                    <img src={Download} alt="Download" className="h-[48px]" />
                  </button>
                </li>
              </ul>
              {/* <button
                className="absolute top-4 right-4"
                onClick={handleCloseModal}
              >
                Close
              </button> */}
            </div>
          </div>
        )}

        {isPopupOpen && (
          <div
            id="popupBackground"
            className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[60]"
            onClick={handleClosePopup}
          >
            <div className="bg-black p-6 rounded-lg text-center">
              <button
                className="bg-blue-500 text-white px-4 py-2 m-2 rounded"
                onClick={() => {
                  markDownloadClicked("ios", "menu_button");
                  window.open(
                    "https://apps.apple.com/us/app/get-home-safe/id6473676583",
                    "_blank"
                  );
                  setIsPopupOpen(false);
                }}
              >
                iOS
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 m-2 rounded"
                onClick={() => {
                  markDownloadClicked("android", "menu_button");
                  window.open(
                    "https://play.google.com/store/apps/details?id=io.gethomesafe.reactnative",
                    "_blank"
                  );
                  setIsPopupOpen(false);
                }}
              >
                Android
              </button>
            </div>
          </div>
        )}
      </>
    );
  }
  return (
    <ul
      id="menuButton"
      className={`md:flex text-[28px] fixed top-0 left-0 w-full p-9 px-20 flex justify-between items-center z-50  ${
        location.pathname.startsWith("/Partners")
          ? location.pathname === "/Partners/Research"
            ? "text-white"
            : "text-black"
          : "text-white"
      }`}
      style={textStyle}
    >
      <li className="self-center items-center justify-center mix-blend-normal">
        <button
          className="text-lg self-center"
          onClick={() => {
            navigate("/");
          }}
        >
          <img src={Logo} alt="GHS" className="h-[58px] mix-blend-normal" />
        </button>
      </li>
      <li className="self-center">
        <button
          className={`text-[28px] uppercase ${
            location.pathname === "/Mission" ? "underline" : ""
          } hover:underline`}
          onClick={() => {
            navigate("/Mission");
          }}
        >
          Our Mission
        </button>
      </li>
      <li className="self-center">
        <button
          className={`text-[28px] uppercase ${
            location.pathname === "/FAQs" ? "underline" : ""
          } hover:underline`}
          onClick={() => {
            navigate("/FAQs");
          }}
        >
          FAQs
        </button>
      </li>
      <li className="self-center">
        <button
          className={`text-[28px] uppercase ${
            location.pathname.startsWith("/Partners") ? "underline" : ""
          } hover:underline`}
          onClick={() => {
            navigate("/Partners");
          }}
        >
          Partners
        </button>
      </li>
      <li className="self-center pt-2 mix-blend-normal">
        <button
          className="self-center mix-blend-normal"
          onClick={handleDownloadClick}
        >
          <img src={Download} alt="Download" className="h-[48px]" />
        </button>
      </li>
      {isPopupOpen && (
        <div
          id="popupBackground"
          className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[60]"
          onClick={handleClosePopup}
        >
          <div className="bg-black p-6 rounded-lg text-center">
            <button
              className="bg-blue-500 text-white px-4 py-2 m-2 rounded"
              onClick={() => {
                markDownloadClicked("ios", "menu_button");
                window.open(
                  "https://apps.apple.com/us/app/get-home-safe/id6473676583",
                  "_blank"
                );
                setIsPopupOpen(false);
              }}
            >
              iOS
            </button>
            <button
              className="bg-green-500 text-white px-4 py-2 m-2 rounded"
              onClick={() => {
                markDownloadClicked("android", "menu_button");

                window.open(
                  "https://play.google.com/store/apps/details?id=io.gethomesafe.reactnative",
                  "_blank"
                );
                setIsPopupOpen(false);
              }}
            >
              Android
            </button>
          </div>
        </div>
      )}
    </ul>
  );
};

export default MenuButton;
